var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "root", staticClass: "main-brands" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "Swiper",
        {
          staticClass: "main-brands__slider",
          attrs: { options: _vm.swiperOptions }
        },
        _vm._l(_vm.brands, function(item, i) {
          return _c(
            "SwiperSlide",
            { key: i, staticClass: "main-brands__brand" },
            [
              _c("a", { attrs: { href: item.url } }, [
                item.imageObject
                  ? _c("img", {
                      staticClass: "main-brands__brand-img lazy",
                      attrs: {
                        "data-src": item.imageObject.src,
                        width: item.imageObject.width,
                        height: item.imageObject.height,
                        alt: item.imageObject.alt
                      }
                    })
                  : _vm._e()
              ])
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "main-brands__link-desktop",
          attrs: { href: "/brands" }
        },
        [_vm._v("Посмотреть все бренды")]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "main-brands__title" }, [
      _c("div", { staticClass: "main-brands__title-text" }, [_vm._v("Бренды")]),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "main-brands__link", attrs: { href: "/brands" } },
        [_vm._v("Все бренды")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }