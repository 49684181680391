<script>
import { SwiperSlide } from 'vue-awesome-swiper';
import { preview } from 'src/utils';

export default {
  components: { SwiperSlide },

  props: {
    item: {
      type: Object,
    },
  },

  computed: {
    itemImage() {
      if (this.item.imageObject) {
        return preview(this.item.imageObject.preview || this.item.imageObject.src, { width: 200 });
      }

      return this.item.image;
    },

    imageSrcSet() {
      const { imageObject } = this.item;

      if (imageObject) {
        return `${preview(imageObject.preview, { width: 400 })} 400w, ${preview(imageObject.preview, {
          width: 200,
        })} 200w`;
      }

      return this.item.image;
    },
  },

  methods: {
    onClickProduct(product, idx) {
      const position = Number(idx.replace('hits', ''));
      this.$emit('clickProduct', { product, position });
    },
  },
};
</script>

<template>
  <swiper-slide class="product-hits__link">
    <a :href="`${item.url}#reviews`">
      <div
        class="mp-widget pointer"
        data-mp-widget-type="SmallProductRating"
        data-mp-show-stars="true"
        data-mp-show-rating-value="true"
        data-mp-show-reviews-count="false"
        data-mp-show-service-logo="false"
        :data-mp-productSKUs="item.sku"
      ></div>
    </a>

    <a class="swiper-slide product-hits__item" @click="onClickProduct(item, $vnode.key)" :href="item.url">
      <div class="product-hits__image-container">
        <img class="product-hits__image custom swiper-lazy" :data-srcset="imageSrcSet" />
      </div>

      <div class="product-hits__name">{{ item.name }}</div>

      <div class="product-hits__price-wrapper">
        <span class="product-hits__price">{{ item.formattedPrice }}</span>
        <span v-if="item.isSale && item.price < item.salePrice" class="product-hits__price product-hits__price--old">
          {{ item.formattedSalePrice }}
        </span>
      </div>
    </a>
  </swiper-slide>
</template>
