var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "catalog-menu", attrs: { id: "catalog-menu" } },
    [
      _c("div", { staticClass: "catalog-menu__title" }, [
        _vm._v("Весь каталог товаров")
      ]),
      _vm._v(" "),
      _c(
        "nav",
        { staticClass: "catalog-menu__wrapper" },
        _vm._l(_vm.menu, function(item, i) {
          return _c(
            "a",
            {
              key: "category-" + i,
              class: [
                "catalog-menu__link",
                "catalog-menu__link--" + item.class
              ],
              attrs: { href: item.link }
            },
            [
              _c("span", { staticClass: "catalog-menu__link-text" }, [
                _vm._v(_vm._s(item.name))
              ]),
              _vm._v(" "),
              item.image_main
                ? _c("img", {
                    staticClass: "catalog-menu__link-img",
                    attrs: { src: item.image_main, alt: item.name }
                  })
                : _vm._e()
            ]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }